import React, { useState, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import {
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  FormControlLabel,
  Checkbox,
  makeStyles,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import { Button, IconButton, landingBlue } from '@clef/client-library';
import CloseIcon from '@material-ui/icons/Close';
import { LabelType, MediaStatusType } from '@clef/shared/types';

import { useTypedSelector } from '@/hooks/useTypedSelector';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useDatasetMediaCountQuery } from '@/serverStore/dataset';
import CLEF_PATH from '@/constants/path';
import ObjectDetectionSvg from '@/images/project/object_detection.svg';
import SegmentationSvg from '@/images/project/segmentation.svg';
import ClassificationSvg from '@/images/project/classification.svg';
import ProjectAPI from '@/api/project_api';
import { projectQueryKeys } from '@/serverStore/projects';
import { defaultSelectOptions } from '@/constants/data_browser';

import SegmentationInstantLearningSvg from '@/images/project/segmentation_instant_learning.svg';
import AnomalyDetectionSvg from '@/images/project/anomaly_detection.svg';
import { CopyIcon } from '@/images/media_details/ToolIcons';
import { ClientFeatures, useFeatureGateEnabled } from '@/hooks/useFeatureGate';
import useSnapshotStyles from './styles';

const CloneProjectTypes = [
  {
    icon: ObjectDetectionSvg,
    type: LabelType.BoundingBox,
    text: 'Object Detection',
  },
  {
    icon: SegmentationSvg,
    type: LabelType.Segmentation,
    text: 'Segmentation',
  },
  {
    icon: ClassificationSvg,
    type: LabelType.Classification,
    text: 'Classification',
  },
  {
    icon: AnomalyDetectionSvg,
    type: LabelType.AnomalyDetection,
    text: 'Anomaly Detection',
  },
  {
    icon: SegmentationInstantLearningSvg,
    type: LabelType.SegmentationInstantLearning,
    text: 'Visual Prompting',
  },
];

const useStyles = makeStyles(theme => ({
  cloneDialogForm: {
    padding: theme.spacing(4, 0, 6, 0),
  },
  projectNameInput: {
    height: 38,
    borderRadius: 6,
    marginBottom: theme.spacing(4),
  },
  subTitleFont: {
    color: theme.palette.grey[800],
    fontWeight: 500,
    marginBottom: theme.spacing(4),
  },
  projectTypeCards: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    height: 76,
    marginBottom: theme.spacing(4),
  },
  projectTypeCard: {
    flex: '1 0 auto',
    minWidth: 138,
    height: '100%',
    marginRight: theme.spacing(2.5),
    padding: theme.spacing(2.5),
    border: `2px solid ${theme.palette.greyModern[200]}`,
    borderRadius: 10,
    '&:hover': {
      cursor: 'pointer',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  selectedCard: {
    border: `2px solid ${theme.palette.blue[600]}`,
  },
  cardIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  cardIcon: {
    width: 20,
    height: 20,
    verticalAlign: 'middle',
    marginRight: theme.spacing(3),
  },
}));

export interface CloneProjectDialogProps {
  version: number;
  onClose(): void;
}

export const CloneProjectDialog: React.FC<CloneProjectDialogProps> = ({ version, onClose }) => {
  const formStyles = useStyles();
  const styles = useSnapshotStyles();

  const currentUser = useTypedSelector(state => state.login.user!);
  const { id: projectId, labelType } = useGetSelectedProjectQuery().data ?? {};

  const isVPEnabled = !useFeatureGateEnabled(ClientFeatures.DisableVisualPrompting);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { data: mediaCount } = useDatasetMediaCountQuery(
    {
      version: version,
      selectOptions: defaultSelectOptions,
    },
    !!version,
  );

  const { data: labeledMediaCount } = useDatasetMediaCountQuery(
    {
      version: version,
      selectOptions: {
        selectedMedia: [],
        unselectedMedia: [],
        isUnselectMode: true,
        fieldFilterMap: {},
        columnFilterMap: {
          datasetContent: { mediaStatus: { CONTAINS_ANY: [MediaStatusType.Approved] } },
        },
      },
    },
    !!version,
  );

  const [projectName, setProjectName] = useState('Untitled Project');
  const [selectedType, setSelectedType] = useState(labelType);
  const [includeLabel, setIncludeLabel] = useState(true);
  const [includeMetaData, setIncludeMetaData] = useState(true);
  const [includeTags, setIncludeTags] = useState(true);

  const handleProjectNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(e.target.value);
  };

  const handleCardClick = (type: LabelType) => {
    setSelectedType(type);
  };

  const [isCloning, setIsCloning] = useState(false);

  const handleCloneProject = useCallback(async () => {
    if (!currentUser.orgId || !projectId) return;

    // if selectedType is current project type, whether to include labels is determined by includeLabel state
    // otherwise, includeLabel is always false
    const includeLabels = selectedType === labelType ? includeLabel : false;
    try {
      setIsCloning(true);
      const { data } = await ProjectAPI.cloneProjectFromVersionedDataset(
        projectId,
        version,
        projectName,
        selectedType!,
        includeLabels,
        includeMetaData,
        includeTags,
      );
      setIsCloning(false);

      // reset form
      setProjectName('Untitled Project');
      setSelectedType(labelType);
      setIncludeLabel(true);
      setIncludeMetaData(true);
      setIncludeTags(true);

      queryClient.invalidateQueries({
        queryKey: projectQueryKeys.list(currentUser.orgId ?? -1),
        refetchType: 'none',
      });

      const newProjectUrl = `${CLEF_PATH.root}/${currentUser.orgId}/pr/${data}`;
      const newProjectFullUrl = window.location.href.split('/app/')[0] + newProjectUrl;

      enqueueSnackbar(
        t('Dataset cloned successfully, new project: {{link}}', {
          link: (
            <Box
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
              onClick={e => {
                e.preventDefault();
                navigator.clipboard.writeText(newProjectFullUrl);
                enqueueSnackbar('New project url copied to clipboard.', {
                  variant: 'success',
                  autoHideDuration: 3000,
                });
              }}
            >
              <a style={{ marginRight: 8, color: landingBlue.main }}>{newProjectFullUrl}</a>
              <CopyIcon color="#ffffff" />
            </Box>
          ),
        }),
        {
          variant: 'success',
          autoHideDuration: 18000,
        },
      );

      onClose();
      window.open(newProjectUrl, '_blank');
    } catch (e) {
      enqueueSnackbar('Failed to clone project. Please try again later.', {
        variant: 'error',
        autoHideDuration: 8000,
      });
    } finally {
      setIsCloning(false);
    }
  }, [
    currentUser.orgId,
    enqueueSnackbar,
    includeLabel,
    includeMetaData,
    includeTags,
    labelType,
    onClose,
    projectId,
    projectName,
    queryClient,
    selectedType,
    version,
  ]);

  const clonableProjectTypes = useMemo(() => {
    if (!isVPEnabled) {
      return CloneProjectTypes.filter(item => item.type !== LabelType.SegmentationInstantLearning);
    }
    if (mediaCount && mediaCount <= 20) return CloneProjectTypes;
    return CloneProjectTypes.filter(item => item.type !== LabelType.SegmentationInstantLearning);
  }, [isVPEnabled, mediaCount]);

  return (
    <Dialog open color="primary" PaperProps={{ className: styles.createDialog }}>
      <DialogTitle className={styles.dialogSection}>
        <Typography variant="h2" className={styles.dialogTitleText}>
          {t('Create a New Project Using This Data Version')}
        </Typography>
        <IconButton
          id="close-clone-project-dialog-btn"
          onClick={onClose}
          className={styles.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classNames(styles.dialogSection, formStyles.cloneDialogForm)}>
        <Typography className={formStyles.subTitleFont}>{t('New Project Name')}</Typography>
        <TextField
          fullWidth
          size="small"
          classes={{ root: formStyles.projectNameInput }}
          variant="outlined"
          value={projectName}
          onChange={handleProjectNameChange}
          placeholder={t('Project name')}
          error={projectName?.length === 0}
          helperText={projectName?.length === 0 && t('This is required.')}
          autoFocus
        />

        <Typography className={formStyles.subTitleFont}>
          {t('How would you like to create your new project?')}
        </Typography>
        <Box className={formStyles.projectTypeCards}>
          {clonableProjectTypes.map(({ icon, type, text }) => (
            <Box
              key={type}
              className={classNames(
                formStyles.projectTypeCard,
                type === selectedType && formStyles.selectedCard,
              )}
              onClick={() => handleCardClick(type)}
            >
              <Box className={formStyles.cardIconWrapper}>
                <img className={formStyles.cardIcon} src={icon} />
                {type === labelType && (
                  <Typography display="inline" variant="body2" style={{ color: '#4D5761' }}>
                    {t('(Current type)')}
                  </Typography>
                )}
              </Box>
              <Typography>{text}</Typography>
            </Box>
          ))}
        </Box>
        <Box marginBottom={2}>
          {selectedType === labelType && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeLabel}
                  color="primary"
                  onClick={() => setIncludeLabel(prev => !prev)}
                />
              }
              label={t('Include labels')}
            />
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={includeMetaData}
                color="primary"
                onClick={() => setIncludeMetaData(prev => !prev)}
              />
            }
            label={t('Include metadata')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={includeTags}
                color="primary"
                onClick={() => setIncludeTags(prev => !prev)}
              />
            }
            label={t('Include tags')}
          />
        </Box>

        {mediaCount && (
          <Typography className={styles.noticeBox}>
            {t(`Your project will have {{mediaCount}} {{labeledMediaCount}} after creation.`, {
              mediaCount: (
                <Typography display="inline" variant="body1" className={styles.mainInfoFont}>
                  {` ${mediaCount} Images`}
                </Typography>
              ),
              labeledMediaCount:
                labeledMediaCount !== undefined ? (
                  <Typography display="inline" variant="body1" className={styles.subInfoFont}>
                    {`(${
                      selectedType === labelType && includeLabel ? labeledMediaCount : 0
                    } Labeled)`}
                  </Typography>
                ) : null,
            })}
          </Typography>
        )}
      </DialogContent>
      <DialogActions className={classNames(styles.dialogSection, styles.dialogActions)}>
        <Button
          id="cancel-clone-project-dialog-btn"
          className={classNames(styles.opsButton, styles.createDialogBtn)}
          color="primary"
          variant="outlined"
          size="small"
          onClick={onClose}
          disabled={isCloning}
        >
          {t('Cancel')}
        </Button>
        <Button
          id="confirm-clone-project-dialog-btn"
          className={classNames(styles.createDialogBtn)}
          color="primary"
          variant="contained"
          onClick={handleCloneProject}
          disabled={isCloning}
        >
          {t('Create New Project')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CloneProjectDialog;
