import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 3, 0, 6),
    height: 48,
    position: 'relative',
    backgroundColor: theme.palette.greyModern[100],
    borderTop: `1px solid ${theme.palette.greyModern[200]}`,
    borderBottom: `1px solid ${theme.palette.greyModern[200]}`,
  },
  labelingTools: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    height: 48,
    padding: theme.spacing(0, 2.5),
    borderRadius: 16,
    color: theme.palette.grey[600],

    '& button': {
      height: 32,
      minWidth: 32,
      padding: 0,
      margin: theme.spacing(0, 1),
      borderRadius: 10,
      color: theme.palette.grey[600],
    },

    '& + &': {
      marginLeft: theme.spacing(3),
    },
  },
  verticalLine: {
    height: 24,
    margin: theme.spacing(0, 2.5),
  },
  centerContentWrapper: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  paginationButton: {
    height: 32,
    minWidth: 32,
    padding: 0,
    margin: theme.spacing(0, 4),
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
  },
  active: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    boxShadow: `0px 0px 12px 0px rgba(0, 0, 0, 0.1)`,
  },
  disabled: {
    opacity: 0.5,
  },
  rightContent: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  labelUpdateStates: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.greyBlue[600],
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  updatingIcon: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      animation: '$rotateIcon 2s linear infinite',
    },
  },
  '@keyframes rotateIcon': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(-360deg)',
    },
  },
  toggleBtns: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',

    '& button': {
      height: 32,
      minWidth: 60,
      padding: theme.spacing(0, 1.5),
      margin: theme.spacing(0, 1),
    },
  },
  drawerBtns: {
    display: 'flex',
    alignItems: 'center',

    '& button': {
      height: 32,
      minWidth: 32,
      padding: 0,
      margin: theme.spacing(0, 1),
      borderRadius: 10,
      color: theme.palette.grey[600],
    },
  },
  zoomOptions: {
    zIndex: theme.zIndex.modal + 1,
  },
  zoomBtn: {
    width: 120,
    height: 36,
  },
  toolItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: 200,
  },
  codeBlock: {
    backgroundColor: theme.palette.greyModern[100],
    borderRadius: 4,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    margin: theme.spacing(1),
    fontWeight: 700,
  },
  labelText: {
    color: theme.palette.greyModern[800],
  },
  textWithIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  infoIcon: {
    marginLeft: '6px',
    color: theme.palette.blue[200],
    width: '20px',
    height: '20px',
  },
}));

export default useStyles;
