import React, { useCallback, useEffect, useState } from 'react';
import { Box, Dialog, makeStyles, Paper } from '@material-ui/core';
import { useAtom } from 'jotai';
import { modelListFilterOptionsAtom } from '../atoms';
import { LabelType, Media } from '@clef/shared/types';
import { EvaluationSetItem } from '@/api/evaluation_set_api';
import { useMediasAnnotationInstancePairsQuery } from '@/serverStore/modelAnalysis';
import { Button, IconButton, Typography, useKeyPress } from '@clef/client-library';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Close from '@material-ui/icons/Close';
import ModelImageDetailStatusBar from './ModelImageDetailStatusBar';
import MediaContainer from '@/pages/DataBrowser/MediaContainer';
import { Skeleton } from '@material-ui/lab';
import classNames from 'classnames';
import AntSwitch from '@clef/client-library/src/components/AntSwitch';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { FilterOptionsChip } from '../ModelImageList/FilterOptionsChip';
import {
  SegmentationImageDiffView,
  ComparisonSegmentationImageDiffView,
} from './SegmentationImageDiffView';
import {
  ComparisonObjectDetectionImageDiffView,
  ObjectDetectionImageDiffView,
} from './ObjectDetectionImageDiffView';

const useStyles = makeStyles(theme => ({
  modelImageDetailDialogPaper: {
    minWidth: 1000,
    width: 1200,
    maxWidth: 'calc(100% - 80px)!important',
  },
  imageListContainer: {
    overflowX: 'auto',
  },
  imageItem: {
    border: `4px solid transparent`,
    borderRadius: 5,
    width: 84,
    height: 84,
    flexGrow: 0,
    flexShrink: 0,
  },
  highlightImage: {
    borderColor: theme.palette.blue[600],
    borderRadius: 5,
  },
  detailMediaContainerRoot: {
    borderRadius: '0 0 10px 10px',
    backgroundColor: theme.palette.greyModern[100],
  },
  itemMediaContainerRoot: {
    borderRadius: 10,
    backgroundColor: theme.palette.greyModern[100],
    cursor: 'pointer',
  },
  diffContainer: {
    background: theme.palette.grey[100],
    borderRadius: '0 0 10px 10px',
    padding: theme.spacing(4, 0),
    gap: theme.spacing(1),
  },
}));

const Toggles: React.FC<{
  isComparisonDialog: boolean;
  showFilteredOnly: boolean;
  setShowFilteredOnly: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isComparisonDialog, showFilteredOnly, setShowFilteredOnly }) => {
  const { data: project } = useGetSelectedProjectQuery();
  const { labelType } = project ?? {};
  const [filterOptions] = useAtom(modelListFilterOptionsAtom);

  if (labelType !== LabelType.Segmentation && labelType !== LabelType.BoundingBox) {
    return null;
  }
  if (!filterOptions) {
    return null;
  }
  if (isComparisonDialog) {
    return (
      <Button
        id="toggle-filtered-regions"
        size="small"
        onClick={() => setShowFilteredOnly(prev => !prev)}
      >
        <AntSwitch color="primary" checked={!showFilteredOnly} />
        <Typography>{t('Show all ground truth and predictions')}</Typography>
      </Button>
    );
  }
  return (
    <Button
      id="toggle-highlight-filtered-pixels"
      size="small"
      onClick={() => setShowFilteredOnly(prev => !prev)}
    >
      <AntSwitch color="primary" checked={showFilteredOnly} />
      <Typography>
        {labelType === LabelType.Segmentation
          ? t('Highlight filtered pixels')
          : t('Highlight filtered instances')}
      </Typography>
    </Button>
  );
};

export type ModelImageDetailDialogProps = {
  mediaId?: number;
  modelId?: string;
  threshold?: number;
  candidateModelId?: string;
  candidateThreshold?: number;
  evaluationSet?: EvaluationSetItem;
  onClose?: () => void;
  mediaList?: Media[];
};

const ModelImageDetailDialog: React.FC<ModelImageDetailDialogProps> = props => {
  const styles = useStyles();
  const {
    mediaId,
    modelId,
    threshold,
    candidateModelId,
    candidateThreshold,
    evaluationSet,
    onClose,
    mediaList = [],
  } = props;
  const [filterOptions] = useAtom(modelListFilterOptionsAtom);
  const [selectedMediaId, setSelectedImageId] = useState(mediaId);
  useEffect(() => {
    setSelectedImageId(mediaId);
  }, [mediaId]);
  let mediaIndex = mediaList.findIndex(m => m.id === selectedMediaId);
  if (mediaIndex === -1) {
    mediaIndex = 0;
  }
  const media = mediaList[mediaIndex];
  const [showFilteredOnly, setShowFilteredOnly] = useState(true);
  const goToPrevMedia = useCallback(() => {
    if (mediaList[mediaIndex - 1]) {
      setSelectedImageId(mediaList[mediaIndex - 1].id);
    }
  }, [mediaIndex, mediaList]);
  const goToNextMedia = useCallback(() => {
    if (mediaList[mediaIndex + 1]) {
      setSelectedImageId(mediaList[mediaIndex + 1].id);
    }
  }, [mediaIndex, mediaList]);
  useKeyPress('left', goToPrevMedia);
  useKeyPress('right', goToNextMedia);
  const { data: project } = useGetSelectedProjectQuery();
  const { labelType } = project ?? {};
  const isClassificationOrAnomalyDetection =
    labelType === LabelType.Classification || labelType === LabelType.AnomalyDetection;
  const isSegmentation = labelType === LabelType.Segmentation;
  const isObjectDetection = labelType === LabelType.BoundingBox;

  const { data: allInstances } = useMediasAnnotationInstancePairsQuery(
    {
      modelId,
      mediaIds: mediaList?.map(media => media.id) ?? [],
      threshold,
      version: evaluationSet?.datasetVersion.version,
    },
    !!filterOptions && isObjectDetection,
  );
  const { data: candidateAllInstances } = useMediasAnnotationInstancePairsQuery(
    {
      modelId: candidateModelId,
      mediaIds: mediaList?.map(media => media.id) ?? [],
      threshold: candidateThreshold,
      version: evaluationSet?.datasetVersion.version,
    },
    !!filterOptions && !!candidateModelId && isObjectDetection,
  );

  return (
    <Dialog
      open={!!mediaId}
      onClose={onClose}
      classes={{ paper: styles.modelImageDetailDialogPaper }}
    >
      <Paper>
        <Box paddingY={6} paddingX={8}>
          {/* Header */}
          <Box display="flex" alignItems="center" marginBottom={4}>
            <Box flex={1} flexBasis="50%">
              {filterOptions && showFilteredOnly ? (
                <FilterOptionsChip />
              ) : (
                <Typography variant="h2_semibold" maxWidth={400}>
                  {media?.name}
                </Typography>
              )}
            </Box>
            {/* prev / next images */}
            <Box flexGrow={0} flexShrink={0} display="flex" alignItems="center">
              <IconButton
                id="model-image-prev-button"
                disabled={mediaIndex === 0}
                onClick={goToPrevMedia}
                size="small"
              >
                <KeyboardArrowLeft />
              </IconButton>
              {mediaIndex + 1}/
              {mediaList.length ? mediaList.length : <Skeleton variant="text" width={14} />}
              <IconButton
                id="model-image-next-button"
                disabled={mediaIndex >= mediaList.length - 1}
                size="small"
                onClick={goToNextMedia}
              >
                <KeyboardArrowRight />
              </IconButton>
            </Box>
            <Box
              flex={1}
              flexBasis="50%"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Toggles
                isComparisonDialog={!!candidateModelId}
                showFilteredOnly={showFilteredOnly}
                setShowFilteredOnly={setShowFilteredOnly}
              />
              <IconButton id="close-model-image-detail-dialog" onClick={onClose} size="small">
                <Close />
              </IconButton>
            </Box>
          </Box>
          {/* Image detail */}
          <Box className={styles.diffContainer} display="flex" height={500} minHeight={500}>
            {media &&
              (isSegmentation || isObjectDetection ? (
                isSegmentation ? (
                  candidateModelId ? (
                    <ComparisonSegmentationImageDiffView
                      media={media}
                      modelId={modelId}
                      versionId={evaluationSet?.datasetVersion.version}
                      threshold={threshold}
                      candidateModelId={candidateModelId}
                      candidateThreshold={candidateThreshold}
                      showFilteredOnly={showFilteredOnly}
                    />
                  ) : (
                    <SegmentationImageDiffView
                      media={media}
                      modelId={modelId}
                      versionId={evaluationSet?.datasetVersion.version}
                      threshold={threshold}
                      showFilteredOnly={showFilteredOnly}
                    />
                  )
                ) : candidateModelId ? (
                  <ComparisonObjectDetectionImageDiffView
                    key={media.id}
                    media={media}
                    rowWidth={900}
                    isDetailDialog
                    baselineModelId={modelId}
                    version={evaluationSet?.datasetVersion.version}
                    baselineThreshold={threshold}
                    baselineAllInstances={allInstances}
                    candidateAllInstances={candidateAllInstances}
                    candidateModelId={candidateModelId}
                    candidateThreshold={candidateThreshold}
                    showFilteredOnly={showFilteredOnly}
                  />
                ) : (
                  <ObjectDetectionImageDiffView
                    key={media.id}
                    media={media}
                    allInstances={allInstances}
                    rowWidth={900}
                    hoverable={false}
                    showTitle
                    modelId={modelId}
                    version={evaluationSet?.datasetVersion.version}
                    threshold={threshold}
                    showFilteredOnly={showFilteredOnly}
                  />
                )
              ) : (
                <MediaContainer
                  media={media}
                  modelId={modelId}
                  enableZoom
                  versionId={evaluationSet?.datasetVersion.version}
                  threshold={threshold}
                  disableSelection
                  showGroundTruth={!isClassificationOrAnomalyDetection}
                  showPredictions={isClassificationOrAnomalyDetection}
                  showClassChip={isClassificationOrAnomalyDetection}
                  disableSetClass
                  noSpacing
                  classes={{ mediaViewerRoot: styles.detailMediaContainerRoot }}
                />
              ))}
          </Box>
          {/* status bar */}
          <ModelImageDetailStatusBar
            mediaId={selectedMediaId}
            modelId={modelId}
            datasetId={evaluationSet?.datasetVersion.datasetId}
            version={evaluationSet?.datasetVersion.version}
          />
          {/* Image list */}
          <Box className={styles.imageListContainer}>
            <Box display="flex" alignItems="center" id="model-image-detail-image-list">
              {mediaList?.map(media => (
                <Box
                  key={media.id}
                  className={classNames(
                    styles.imageItem,
                    selectedMediaId === media.id && styles.highlightImage,
                  )}
                >
                  <MediaContainer
                    media={media}
                    modelId={modelId}
                    versionId={evaluationSet?.datasetVersion.version}
                    threshold={threshold}
                    disableSelection
                    showGroundTruth={false}
                    showPredictions={false}
                    showClassChip={false}
                    onInfoClick={() => setSelectedImageId(media.id)}
                    noSpacing
                    classes={{ mediaViewerRoot: styles.itemMediaContainerRoot }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Paper>
    </Dialog>
  );
};

export default ModelImageDetailDialog;
