import React, { useCallback, useMemo, useState } from 'react';
import { Box, CircularProgress, Tooltip, makeStyles, MenuList, MenuItem } from '@material-ui/core';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { Button, Dropdown, Typography } from '@clef/client-library';
import { isNumber } from 'lodash';
import { Skeleton } from '@material-ui/lab';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import {
  LabelType,
  MetricsItem,
  ModelEvaluationReportStatus,
  RegisteredModel,
} from '@clef/shared/types';
import {
  useGetConfusionMatrixQuery,
  useGetModelEvaluationReportsQuery,
  useGetModelMediaListInfiniteQuery,
} from '@/serverStore/modelAnalysis';
import { EvaluationSetItem } from '@/api/evaluation_set_api';
import { isModelTrainingSuccessful } from '@/store/projectModelInfoState/utils';
import { useSnackbar } from 'notistack';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useModelAnalysisCreateBundleMutation } from '@/serverStore/modelAnalysis/mutations';
import model_analysis_api from '@/api/model_analysis_api';
import AdjustThresholdDropdown from './ModelDetailsPanel/AdjustThresholdDropdown';
import { DownloadIcon } from '@/images/media_details/ToolIcons';

const useStyles = makeStyles(theme => ({
  progressRoot: {
    position: 'absolute',
    left: 0,
    top: 0,
  },
  headerBar: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(7),
    marginBottom: theme.spacing(4),
  },
  progressBg: {
    // color: '#FEDF89',
    color: theme.palette.blue[200],
  },
  progressFg: {
    // color: '#F79009',
    color: theme.palette.blue[500],
  },
  thresholdText: {
    fontSize: 14,
  },
  circularChipContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(15),
    padding: theme.spacing(8, 12),
    justifyContent: 'center',
    borderRadius: 10,
    background: theme.palette.grey[50],
  },
  chipInfo: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.greyModern[500],
  },
}));

enum PerformanceType {
  F1 = 'f1',
  Iou = 'iou',
  Precision = 'precision',
  Recall = 'recall',
}

const ChipTitle = {
  [PerformanceType.F1]: t('F1'),
  [PerformanceType.Iou]: t('IOU'),
  [PerformanceType.Precision]: t('Precision'),
  [PerformanceType.Recall]: t('Recall'),
};

const ChipTooltipInfo = {
  [PerformanceType.F1]:
    'The F1 score combines precision and recall into a single score, creating a unified measure that assesses the model’s effectiveness in minimizing false positives and false negatives. A higher F1 score indicates the model is balancing the two factors well. We calculate F1 using micro-averaging.',
  [PerformanceType.Iou]:
    'Intersection over Union (IOU) is used to measure the accuracy of the model by measuring the overlap between the predicted and actual masks in an image. A higher IOU indicates better agreement between the ground truth and predicted mask. For IoU we ignore the implicit background and micro-averaging. {{learnMore}}',
  [PerformanceType.Precision]: 'Precision',
  [PerformanceType.Recall]: 'Recall',
};

const CircularPerformanceChip: React.FC<{
  score?: number | null;
  performanceType: PerformanceType;
}> = ({ score, performanceType }) => {
  const styles = useStyles();
  return (
    <Box display="flex" alignItems="center">
      <Box position="relative" width={48} height={48}>
        <CircularProgress
          size={48}
          variant="determinate"
          thickness={6}
          value={100}
          classes={{ root: styles.progressRoot, colorPrimary: styles.progressBg }}
        />
        <CircularProgress
          size={48}
          variant="determinate"
          thickness={6}
          value={(score ?? 0) * 100}
          classes={{ root: styles.progressRoot, colorPrimary: styles.progressFg }}
        />
      </Box>
      <Box marginLeft={4}>
        <Box marginBottom={1}>
          <Typography variant="h2">
            {isNumber(score) ? (
              score === -1 ? (
                t('N/A')
              ) : (
                t('{{score}}%', { score: (score * 100).toFixed(1) })
              )
            ) : (
              <Skeleton variant="text" />
            )}
          </Typography>
          <Box className={styles.chipInfo}>
            <Typography>{ChipTitle[performanceType]}</Typography>
            <Box marginLeft={1} />
            <Tooltip
              placement="top"
              arrow
              interactive
              title={t(ChipTooltipInfo[performanceType], {
                learnMore: (
                  <Typography
                    style={{
                      display: 'inline',
                      cursor: 'pointer',
                      marginLeft: 4,
                      textDecoration: 'underline',
                      fontSize: '12px',
                    }}
                    onClick={() => {
                      window.open(
                        'https://support.landing.ai/landinglens/docs/compare-models#segmentation-intersection-over-union-iou',
                        '_blank',
                      );
                    }}
                  >
                    {t('Learn more.')}
                  </Typography>
                ),
              })}
            >
              <InfoOutlined fontSize="small" />
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export type ModelAnalysisSummaryProps = {
  initialThreshold: number;
  performance?: MetricsItem | null;
  model?: RegisteredModel;
  threshold: number;
  evaluationOptions: Array<{
    evaluationSet: EvaluationSetItem;
    name: string;
  }>;
  evaluationSet?: EvaluationSetItem | null;
  onChangeEvaluationSet?: (e: EvaluationSetItem) => void;
  setThreshold: (threshold: number) => void;
};

// Format a large number to readable form. Espectially useful for segmentation pixels
// 1~999 -> original number
// 1000~999,999 -> 1.0k to 999.9k
// 1,000,000~?  -> 1.0M to ????.?M
const humanReadableNumber = (value: number) => {
  if (value < 1e3) {
    return value;
  }
  if (value < 1e6) {
    return (value / 1000).toFixed(1) + 'K';
  }
  return (value / 1e6).toFixed(1) + 'M';
};

const ModelAnalysisSummary: React.FC<ModelAnalysisSummaryProps> = props => {
  const styles = useStyles();
  const {
    model,
    threshold,
    evaluationSet,
    initialThreshold,
    onChangeEvaluationSet,
    setThreshold,
    evaluationOptions,
    performance,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { id: projectId, labelType } = useGetSelectedProjectQuery().data ?? {};
  const createBundle = useModelAnalysisCreateBundleMutation(model?.modelName || undefined);
  const [adjustThresholdDropdownAnchorEl, setAdjustThresholdDropdownAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const { data: evaluationReports } = useGetModelEvaluationReportsQuery(model?.id);
  const completedReports = (evaluationReports ?? []).filter(
    r => r.status === ModelEvaluationReportStatus.COMPLETED && r.threshold === initialThreshold,
  );

  const selectedOption =
    evaluationOptions.find(option => option.evaluationSet.id === evaluationSet?.id) ??
    (evaluationOptions.length ? evaluationOptions[0] : null);
  const selectedEvaluationSet = selectedOption?.evaluationSet ?? null;

  const { data: mediaListRes } = useGetModelMediaListInfiniteQuery(
    model?.id,
    threshold,
    evaluationSet,
  );
  const totalMedias = mediaListRes?.pages[0]?.total;
  const { data: confusionMatrixData } = useGetConfusionMatrixQuery(
    model?.id,
    evaluationSet?.id,
    threshold,
  );
  const { correct, misClassification, falseNegative, falsePositive } =
    confusionMatrixData?.splitConfusionMatrices ?? {};
  const totalInstances =
    (correct?.count ?? 0) +
    (misClassification?.count ?? 0) +
    (falseNegative?.count ?? 0) +
    (falsePositive?.count ?? 0);

  const imagesAndInstancesNumberComponent = useMemo(() => {
    if (totalMedias === undefined || totalInstances === undefined) {
      return <Skeleton variant="text" width={100} />;
    }
    if (labelType === LabelType.BoundingBox) {
      return t('({{totalMedias}} images, {{totalInstances}} instances labeled)', {
        totalMedias,
        totalInstances,
      });
    }
    if (labelType === LabelType.Segmentation) {
      return t('({{totalMedias}} images, {{totalInstances}} pixels labeled)', {
        totalMedias,
        totalInstances: humanReadableNumber(totalInstances),
      });
    }
    return t('({{totalMedias}} images)', {
      totalMedias,
    });
  }, [labelType, totalInstances, totalMedias]);

  const [downloadCsvLoading, setDownloadCsvLoading] = useState(false);
  const hideAdjustThresholdButton = labelType === LabelType.Classification;

  const onThresholdSave = async (newThreshold: number) => {
    if (model && selectedEvaluationSet && newThreshold !== undefined) {
      if (!isModelTrainingSuccessful(model.status, model.metricsReady)) {
        enqueueSnackbar(t('The model has not yet finish training, Please try again later.'), {
          variant: 'warning',
          autoHideDuration: 12000,
        });
        return;
      }
      await createBundle.mutateAsync({
        modelId: model.id,
        evaluationSetId: selectedEvaluationSet.id,
        threshold: newThreshold,
      });
    }
    setThreshold(newThreshold);
    setAdjustThresholdDropdownAnchorEl(null);
  };

  const onThresholdCancel = () => {
    setAdjustThresholdDropdownAnchorEl(null);
  };

  const modelEvaluationReport = completedReports.find(report => {
    return (
      report.evaluationSetId === selectedEvaluationSet?.id &&
      report.threshold === threshold &&
      report.modelId === model?.id
    );
  });
  const enableDownloadCSV =
    labelType === LabelType.BoundingBox || labelType === LabelType.Classification;

  const handleDownloadCsvClicked = useCallback(async () => {
    if (!projectId) return;
    if (!modelEvaluationReport) return;
    try {
      setDownloadCsvLoading(true);
      const res = await model_analysis_api.getModelEvaluationReportCsv(
        projectId,
        modelEvaluationReport.id,
      );
      window.open(res);
    } catch (e) {
      enqueueSnackbar(
        t(`Failed to download CSV. {{errorMessage}}`, {
          errorMessage: e.message,
        }),
        {
          variant: 'error',
          autoHideDuration: 3000,
        },
      );
    } finally {
      setDownloadCsvLoading(false);
    }
  }, [projectId, modelEvaluationReport, enqueueSnackbar]);

  return (
    <Box id="evaluation-set-and-performance-summary">
      <Box className={styles.headerBar} id="evaluation-set-and-performance-row">
        {/* evaluation set */}
        <Box display="flex" alignItems="center">
          <Box marginRight={3}>
            <Typography>{t('Evaluation set:')}</Typography>
          </Box>
          <Dropdown
            dropdown={toggleDropdown => (
              <MenuList>
                {evaluationOptions.map(option => (
                  <MenuItem
                    key={option.evaluationSet.id}
                    selected={option.evaluationSet.id === evaluationSet?.id}
                    onClick={() => {
                      onChangeEvaluationSet?.(option.evaluationSet);
                      toggleDropdown(false);
                    }}
                  >
                    {t(option.name)}
                  </MenuItem>
                ))}
              </MenuList>
            )}
          >
            <Box display="flex" alignItems="center">
              <Typography>{selectedOption?.name}</Typography>
              <ExpandMore fontSize="small" />
            </Box>
          </Dropdown>
        </Box>
        {
          <Box display="flex" alignItems="center">
            <Typography>
              {t('Labeled Data: ')}
              <span>{imagesAndInstancesNumberComponent}</span>
            </Typography>
          </Box>
        }
        {/* threshold */}
        {labelType !== LabelType.Classification && (
          <Box
            display="flex"
            alignItems="center"
            id="confidence-threshold-section"
            className={styles.thresholdText}
          >
            {t('{{thresholdText}}:{{thresholdValue}}', {
              thresholdText:
                labelType === LabelType.AnomalyDetection
                  ? t('Anomaly Threshold')
                  : t('Confidence Threshold'),
              thresholdValue: (
                <Box marginLeft={3} component={'span'}>
                  <Typography display="inline">{threshold}</Typography>
                </Box>
              ),
            })}
            {!hideAdjustThresholdButton && (
              <Box>
                <Button
                  id="adjust-threshold"
                  variant="text"
                  color="primary"
                  onClick={event => {
                    setAdjustThresholdDropdownAnchorEl(event.currentTarget);
                  }}
                >
                  {t('Adjust')}
                </Button>
                <AdjustThresholdDropdown
                  model={model}
                  initialThreshold={threshold}
                  anchorEl={adjustThresholdDropdownAnchorEl}
                  evaluationSetId={evaluationSet?.id}
                  handleClose={() => {
                    setAdjustThresholdDropdownAnchorEl(null);
                  }}
                  onSave={onThresholdSave}
                  onCancel={onThresholdCancel}
                />
              </Box>
            )}
          </Box>
        )}
        {modelEvaluationReport && enableDownloadCSV && (
          <Box
            display="flex"
            alignItems="center"
            marginLeft={7}
            flex={1}
            flexDirection={'row'}
            justifyContent={'flex-end'}
          >
            <Button
              variant="outlined"
              id={'download-model-evaluation-report-csv'}
              startIcon={<DownloadIcon />}
              onClick={handleDownloadCsvClicked}
              disabled={!projectId || !modelEvaluationReport || downloadCsvLoading}
            >
              {t('Download CSV')}
            </Button>
          </Box>
        )}
      </Box>
      <Box className={styles.circularChipContainer}>
        <CircularPerformanceChip
          score={performance?.performance}
          performanceType={
            labelType === LabelType.Segmentation ? PerformanceType.Iou : PerformanceType.F1
          }
        />
        <CircularPerformanceChip
          score={performance?.precision}
          performanceType={PerformanceType.Precision}
        />
        <CircularPerformanceChip
          score={performance?.recall}
          performanceType={PerformanceType.Recall}
        />
      </Box>
    </Box>
  );
};

export default ModelAnalysisSummary;
