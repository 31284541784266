import React, { useCallback, useMemo, useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { Button } from '@clef/client-library';
import { useModels } from '@/hooks/useModels';
import { isUnsavedModel } from '@/utils/models';
import { SaveModelDialog } from '@/pages/DataBrowser/ModelPerformance/SaveModelDialog';
import DeployModelDialog from '@/components/Dialogs/DeployModelDialog';
import { LabelType } from '@clef/shared/types';
import { generateModelNameByDate } from '@/pages/DataBrowser/InstantLearningModels/utils';
import { useSaveModelMutation } from '@/serverStore/projectModels';

type DeployComponentProps = {
  modelInfo:
    | {
        id: string | undefined;
        threshold: number | undefined;
      }
    | undefined
    | null;
  labelType?: LabelType | null | undefined;
};

export const DeployComponent: React.FC<DeployComponentProps> = ({ modelInfo, labelType }) => {
  const [deployModelDialogOpen, setDeployModelDialogOpen] = useState(false);
  const [saveModelDialogOpen, setSaveModelDialogOpen] = useState(false);
  const { findModels } = useModels();
  const saveModel = useSaveModelMutation();
  const model = useMemo(() => findModels(modelInfo?.id), [findModels, modelInfo?.id]);
  const isDeployPending = saveModel.isLoading;

  const autoSaveModel = useCallback(async () => {
    if (model?.id === undefined) {
      throw Error('modelId is undefined!');
    }
    saveModel.mutate({
      id: model.id,
      modelName: generateModelNameByDate(),
    });
  }, [model?.id, saveModel]);

  return (
    <>
      <Box display="flex" width="100%" marginBottom={3} justifyContent="flex-end">
        <Button
          id="predict-dialog-deploy-button"
          variant="contained"
          color="primary"
          startIcon={isDeployPending ? <CircularProgress size="16px" /> : null}
          disabled={isDeployPending}
          onClick={() => {
            if (model) {
              if (isUnsavedModel(model)) {
                if (labelType === LabelType.SegmentationInstantLearning) {
                  autoSaveModel();
                } else {
                  setDeployModelDialogOpen(true);
                }
              } else {
                setDeployModelDialogOpen(true);
              }
            }
          }}
        >
          {t('Deploy')}
        </Button>
      </Box>

      <SaveModelDialog
        open={saveModelDialogOpen}
        onClose={() => {
          setSaveModelDialogOpen(false);
        }}
        hintText={t('You need to save the model before deployment.')}
        onSaveModelSucceed={() => {
          setDeployModelDialogOpen(true);
        }}
        modelId={model?.id}
      />

      <DeployModelDialog
        open={deployModelDialogOpen}
        onClose={() => setDeployModelDialogOpen(false)}
        modelInfo={modelInfo}
      />
    </>
  );
};
