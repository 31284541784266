import React, { useMemo, useState } from 'react';
import {
  Box,
  Grid,
  Link,
  makeStyles,
  Tooltip,
  Popover,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { getModelName, isFastNEasyModel } from '@/utils/models';
import { Button, IconButton, TabManager, Typography } from '@clef/client-library';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import HistoricalData from './HistoricalData';
import SetupDeployment from './SetupDeployment';
import { DefectId, DefectMapRecord, DeployedModel, Endpoint, LabelType } from '@clef/shared/types';
import { useAllModels } from '@/hooks/useModels';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import ContentCopySvg from '@/images/deploy/content_copy.svg';
import { generateImageLevelClassificationText } from '@/pages/DataBrowser/InstantLearningModels/utils';
import InfoIcon from '@material-ui/icons/Info';
import cx from 'classnames';
import {
  ClientFeatures,
  useFeatureGateEnabled,
  useModelAnalysisEnabled,
} from '@/hooks/useFeatureGate';
import qrcodeIcon from '@/images/qrcode_icon.svg';
import MobileInferenceDialog from './MobileInferenceDialog';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { rootElement } from '@/utils/dom_utils';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useModelInferenceCost } from '@/hooks/api/useExperimentReportApi';
import CLEF_PATH from '@/constants/path';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  commonFontWeight: {
    fontWeight: 500,
    marginRight: theme.spacing(2),
  },
  endpointDetailsContainer: {
    minHeight: '100%',
    minWidth: '960px',
  },
  endpointBasicInfoContainer: {
    marginBottom: 40,
    gap: theme.spacing(1),
  },
  endpointBasicInfoTitle: {
    fontWeight: 700,
    color: '#697586',
  },
  endpointHostname: {
    cursor: 'pointer',
  },
  marginRight3: {
    marginRight: theme.spacing(3),
  },
  setupDeployment: {
    border: `1px solid ${theme.palette.greyModern[300]}`,
    borderRadius: 20,
    padding: 24,
  },
  iconButton: {
    borderRadius: 6,
    width: 30,
    height: 30,
  },
  marginLeft2: {
    marginLeft: theme.spacing(2),
  },
  textWithIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoIcon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.blue[200],
    width: 20,
    height: 20,
  },
  moreButton: {
    alignSelf: 'baseline',
    borderRadius: '10px',
    border: `1px solid ${theme.palette.greyBlue[300]}`,
  },
  moreButtonOption: {
    '&:hover': {
      background: '#F3F3F4',
      cursor: 'pointer',
    },
  },
  link: {
    cursor: 'pointer',
  },
}));

export type EndpointDetailsProps = {
  endpoint: {
    endpoint: Endpoint;
    model: DeployedModel | null;
  } | null;
  onModelDetailsButtonClick?: () => void | Promise<void>;
  onUpdateDeploymentButtonClick?: () => void | Promise<void>;
  onPredictButtonClick?: () => void | Promise<void>;
  onViewApiCommandButtonClick?: () => void | Promise<void>;
  onViewApiKeyAndSecretButtonClick?: () => void | Promise<void>;
  labelType?: LabelType | null;
};

/**
 * 2024 Feb 26: Endpoint Types explanation
 * - dynamic: These are the cloud inference endpoints and are actively used. These are the ones that appear in the "Cloud Deployment" section in the deploy page.
 * - edge: These are the edge devices and docker deployments. They are actively used too. These appear in the "Self Hosted Deployment" section in the deploy page.
 * - static: These were the old cloud inference endpoints. We have completely deprecated these endpoints and are no longer in use. There might still be some database entries for these, so we need to be careful to handle the case, but they are no longer used.
 */

const EndpointDetails: React.FC<EndpointDetailsProps> = ({
  endpoint,
  onModelDetailsButtonClick,
  onUpdateDeploymentButtonClick,
  onPredictButtonClick,
  onViewApiCommandButtonClick,
  onViewApiKeyAndSecretButtonClick,
  labelType,
}) => {
  const styles = useStyles();
  const history = useHistory();
  const { findModels } = useAllModels({});
  const model = useMemo(
    () => findModels(endpoint?.model?.jobId),
    [endpoint?.model?.jobId, findModels],
  );
  const isFastAndEasyModel = isFastNEasyModel(model);
  const instantLearningPostprocessingEnabled = useFeatureGateEnabled(
    ClientFeatures.InstantLearningPostprocessing,
  );
  const shouldUseAdvancedPricing = useFeatureGateEnabled(ClientFeatures.AdvancedUsageBasedPricing);
  const isCreditReferenceEnabled = !useFeatureGateEnabled(ClientFeatures.DisableCreditReference);
  const { id: projectId } = useGetSelectedProjectQuery().data ?? {};
  const enableModelAnalysis = useModelAnalysisEnabled();
  const isMobileInferenceEnabled = !useFeatureGateEnabled(ClientFeatures.DisableMobileInference);
  const copyToClipboard = useCopyToClipboard({
    text: endpoint?.endpoint.id ?? '',
    successMessage: t('Endpoint id copied to clipboard'),
  });
  const [inferenceCost] = useModelInferenceCost(
    shouldUseAdvancedPricing && endpoint?.model?.jobId && projectId
      ? {
          projectId,
          jobId: endpoint?.model?.jobId,
        }
      : undefined,
  );

  const generateNoiseFilterText = (
    defectMap: Record<string, DefectMapRecord> | undefined,
    defectIdToSegmentationAreaThreshold: Record<DefectId, number> | undefined,
  ) => {
    if (defectMap === undefined || defectIdToSegmentationAreaThreshold == undefined) {
      return 'No Noise filter';
    }
    return Object.values(defectMap).reduce(
      (noiseFilterText, defect) =>
        defect.defectId === undefined
          ? ''
          : `${noiseFilterText ? `${noiseFilterText}, ` : ''}${defect.name} > ${
              defectIdToSegmentationAreaThreshold[defect.defectId] ?? 0
            }`,
      '',
    );
  };

  const noiseFilterText = useMemo(
    () =>
      generateNoiseFilterText(
        endpoint?.model?.actionPipeline?.steps?.[0]?.params.defect_map,
        endpoint?.model?.actionPipeline?.steps?.[0]?.params.thresholds,
      ),
    [endpoint],
  );

  const imageLevelClassificationText = useMemo(
    () => generateImageLevelClassificationText(endpoint?.model?.actionPipeline?.steps?.[1]?.params),
    [endpoint],
  );

  const [openMobileInferenceDialog, setOpenMobileInferenceDialog] = useState(false);

  if (!endpoint) {
    return null;
  }

  return (
    <Box flex={4} className={styles.endpointDetailsContainer}>
      <Box marginBottom={8} display="flex" alignItems="center">
        <Typography variant="h2" className={styles.commonFontWeight}>
          {endpoint.endpoint.name}
        </Typography>

        <IconButton
          id="copy-endpoint-id"
          className={styles.iconButton}
          tooltip={t('Copy endpoint id')}
          onClick={copyToClipboard}
        >
          <img src={ContentCopySvg} width={18} height={18} />
        </IconButton>
        {isMobileInferenceEnabled && endpoint.endpoint.type === 'dynamic' && (
          <IconButton
            id="open-public-endpoint-dialog"
            tooltip={t('Mobile Inference')}
            className={styles.iconButton}
            onClick={() => setOpenMobileInferenceDialog(true)}
          >
            <img src={qrcodeIcon} width={18} height={18} />
          </IconButton>
        )}

        {openMobileInferenceDialog && (
          <MobileInferenceDialog
            inferenceCost={inferenceCost?.data.inferenceCredits ?? 1}
            onClose={() => setOpenMobileInferenceDialog(false)}
            endpointId={endpoint.endpoint.id}
          />
        )}
      </Box>

      {endpoint.endpoint.type === 'static' ? (
        // Static endpoint/device will show hostname and historical data
        <>
          <Grid container className={styles.endpointBasicInfoContainer}>
            <Box display="flex" flexDirection="column" marginRight={15}>
              <Typography className={styles.endpointBasicInfoTitle}>{t('Hostname')}</Typography>

              <Box
                display="flex"
                alignItems="center"
                height={30}
                className={styles.endpointHostname}
              >
                <Link
                  onClick={() =>
                    window.open(
                      `https://${endpoint.endpoint.hostname}`,
                      '_blank',
                      'noopener,noreferrer',
                    )
                  }
                >
                  <Typography className={styles.commonFontWeight}>
                    {`https://${endpoint.endpoint.hostname}`}
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Grid>

          <TabManager
            tabs={[
              {
                key: 'historical-data',
                title: t('Historical Data'),
                component: (
                  <HistoricalData
                    inferenceCost={inferenceCost?.data.inferenceCredits ?? 1}
                    endpoint={endpoint.endpoint}
                    onViewApiKeyAndSecretButtonClick={onViewApiKeyAndSecretButtonClick}
                  />
                ),
              },
            ]}
            defaultTab="historical-data"
          />
        </>
      ) : // Dynamic endpoint/device will show details and historical data if having a deployed model;
      // Edge endpoint/device will always show details and historical data
      endpoint.endpoint.type === 'edge' || endpoint.model ? (
        <>
          <Grid container className={styles.endpointBasicInfoContainer}>
            <Box display="flex" flexDirection="column" marginRight={15}>
              <Typography className={styles.endpointBasicInfoTitle}>{t('Model')}</Typography>

              <Box display="flex" alignItems="center" height={30}>
                <Typography className={styles.commonFontWeight} width={200}>
                  {typeof endpoint?.model?.jobId === 'string'
                    ? getModelName(model)
                    : t('No model deployed')}
                </Typography>

                {/* Model details dialog can be available only for fast-n-easy model and dynamic
                  device/endpoint */}
                {isFastAndEasyModel && endpoint.endpoint.type === 'dynamic' && (
                  <IconButton
                    id="fre-deploy-page-model-details-button"
                    size="small"
                    onClick={onModelDetailsButtonClick}
                    className={styles.marginLeft2}
                  >
                    <MoreHorizIcon />
                  </IconButton>
                )}
              </Box>
            </Box>

            {labelType === LabelType.SegmentationInstantLearning ? (
              instantLearningPostprocessingEnabled ? (
                <>
                  <Box display="flex" flexDirection="column" marginRight={15} maxWidth={'25%'}>
                    <Typography className={cx(styles.endpointBasicInfoTitle, styles.textWithIcon)}>
                      {t('Noise filtering')}
                      <Tooltip
                        placement="top"
                        title={t('Suppress predicted regions below a certain area')}
                        arrow
                      >
                        <InfoIcon className={styles.infoIcon} />
                      </Tooltip>
                    </Typography>
                    <Tooltip placement="top" title={noiseFilterText} arrow>
                      <Box display="flex" alignItems="center" height={30}>
                        <Typography className={styles.commonFontWeight} noWrap>
                          {noiseFilterText}
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Box>
                  <Box display="flex" flexDirection="column" marginRight={15} maxWidth={'25%'}>
                    <Typography
                      className={cx(styles.endpointBasicInfoTitle, styles.textWithIcon)}
                      noWrap
                    >
                      {t('Image-Level classification')}
                      <Tooltip
                        placement="top"
                        title={t('Apply rules to assign an image-level output')}
                        arrow
                      >
                        <InfoIcon className={styles.infoIcon} />
                      </Tooltip>
                    </Typography>
                    <Tooltip placement="top" title={imageLevelClassificationText} arrow>
                      <Box display="flex" alignItems="center" height={30}>
                        <Typography className={styles.commonFontWeight}>
                          {imageLevelClassificationText}
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Box>
                </>
              ) : null
            ) : (
              <>
                {labelType !== LabelType.Classification ? (
                  <Box display="flex" flexDirection="column" marginRight={15}>
                    <Typography className={styles.endpointBasicInfoTitle}>
                      {labelType === LabelType.AnomalyDetection
                        ? t('Anomaly Threshold')
                        : t('Confidence Threshold')}
                    </Typography>

                    <Box display="flex" alignItems="center" height={30}>
                      <Typography className={styles.commonFontWeight}>
                        {typeof endpoint?.model?.jobId === 'string'
                          ? endpoint.model?.threshold ?? t('Unknown threshold')
                          : t('No model deployed')}
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
              </>
            )}

            {isCreditReferenceEnabled && inferenceCost?.data.inferenceCredits && (
              <Box display="flex" flexDirection="column" marginRight={15}>
                <Typography className={cx(styles.endpointBasicInfoTitle, styles.textWithIcon)}>
                  {t('Prediction Cost')}
                  <Tooltip
                    placement="top"
                    title={t(
                      'Prediction cost is calculated based on training settings (image resize, model size) and project type. ',
                    )}
                    arrow
                  >
                    <InfoIcon className={styles.infoIcon} />
                  </Tooltip>
                </Typography>

                <Box display="flex" alignItems="center" height={30}>
                  <Typography>
                    {t(
                      `{{inferenceCost}}${
                        inferenceCost.data.inferenceCredits > 1 ? 'credits' : 'credit'
                      } / image`,
                      {
                        inferenceCost: (
                          <span className={styles.commonFontWeight}>
                            {inferenceCost.data.inferenceCredits}
                          </span>
                        ),
                      },
                    )}
                  </Typography>
                </Box>
              </Box>
            )}

            {/* Update deployment and predict dialog can be available only for fast-n-easy model and
              dynamic device/endpoint */}
            {endpoint.endpoint.type === 'dynamic' && (
              <>
                {isFastAndEasyModel && (
                  <>
                    {!enableModelAnalysis && (
                      <Button
                        id="fre-deploy-page-update-deployment-button"
                        variant="contained"
                        color="primary"
                        className={styles.marginRight3}
                        onClick={onUpdateDeploymentButtonClick}
                      >
                        {t('Update Deployment')}
                      </Button>
                    )}
                    {labelType !== LabelType.SegmentationInstantLearning && (
                      <Button
                        id="fre-deploy-page-predict-button"
                        variant="outlined"
                        color="primary"
                        tooltip={t('View where the AI predicted classes in images.')}
                        className={styles.marginRight3}
                        onClick={onPredictButtonClick}
                      >
                        {t('Try this model')}
                      </Button>
                    )}
                  </>
                )}

                <PopupState variant="popover" popupId="endpoint-more-btn-popup-popover">
                  {popupState => (
                    <>
                      <IconButton
                        id="fre-deploy-page-model-more-button"
                        data-testid="fre-deploy-page-model-more-button"
                        size="small"
                        className={styles.moreButton}
                        {...bindTrigger(popupState)}
                      >
                        <MoreHorizIcon />
                      </IconButton>
                      <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        container={rootElement}
                        disableEnforceFocus
                      >
                        <List component="nav">
                          <ListItem
                            id="fre-deploy-page-view-command-button"
                            role="menuitem"
                            className={styles.moreButtonOption}
                            onClick={onViewApiCommandButtonClick}
                          >
                            <ListItemText primary={t('View API Command')} />
                          </ListItem>
                        </List>
                      </Popover>
                    </>
                  )}
                </PopupState>
              </>
            )}
          </Grid>

          <HistoricalData
            endpoint={endpoint.endpoint}
            inferenceCost={inferenceCost?.data.inferenceCredits ?? 1}
            onViewApiKeyAndSecretButtonClick={onViewApiKeyAndSecretButtonClick}
            onQrCodeGenerated={() => setOpenMobileInferenceDialog(true)}
          />
        </>
      ) : // Dynamic endpoint/device will show the deployment setup UI if no model has been deployed
      enableModelAnalysis ? (
        <Typography>
          {t('Please go to {{link}} to deploy.', {
            link: (
              <Link className={styles.link} onClick={() => history.push(CLEF_PATH.modelsV2.list)}>
                {t('models page')}
              </Link>
            ),
          })}
        </Typography>
      ) : (
        <SetupDeployment
          endpointId={endpoint.endpoint.id}
          classes={{ root: styles.setupDeployment }}
        />
      )}
    </Box>
  );
};

export default EndpointDetails;
