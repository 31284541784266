import React, { useCallback, useMemo, useState } from 'react';
import { Media, MediaId, MediaStatusType } from '@clef/shared/types';

import {
  DraftActionSelectingMedia,
  isMediaSelected,
  useDataBrowserState,
} from '../dataBrowserState';
import { useKeyPress, VirtualGrid } from '@clef/client-library';
import MediaContainer from '../MediaContainer';
import { useWorkflowAssistantState } from '../../../components/WorkflowAssistant/state';
import { makeStyles } from '@material-ui/core';
import { StepName } from '../../../types/client';

const useStyles = makeStyles(() => ({
  virtualGrid: {
    overflow: 'visible',
  },
}));

export interface MediaGridProps {
  medias: Media[];
  onInfoClick?: (mediaId: MediaId) => void;
  disableVirtualGrid?: boolean;
  disableSelection?: boolean;
  showClassChip?: boolean;
  showGroundTruth?: boolean;
  showPredictions?: boolean;
  segmentationOpacity?: number;
  enableGrayscaleImage?: boolean;
  renderMediaOverlay?(media: Media, index: number): JSX.Element | null;
  imageRatio?: number;
}

export const calcOptimalRatio = (medias: Media[]) => {
  const allRatios = medias
    .filter(media => media.properties && media.properties.height && media.properties.width)
    .map(media => media.properties!.height / media.properties!.width)
    .sort((a, b) => a - b);
  // Lazy here to calculate the most frequent item
  return allRatios.length ? allRatios[Math.floor(allRatios.length / 2)] : 16 / 9;
};

const MediaGrid: React.FC<MediaGridProps> = ({
  medias,
  onInfoClick,
  disableVirtualGrid,
  showClassChip = false,
  disableSelection = false,
  showGroundTruth: showGroundTruthProps,
  showPredictions: showPredictionsProps,
  segmentationOpacity = 0.3,
  enableGrayscaleImage = false,
  renderMediaOverlay,
  imageRatio,
}) => {
  const styles = useStyles();
  const { state, dispatch } = useDataBrowserState();

  const { labelDisplay, showGroundTruth, showPredictions, itemPerRowOffset } = state;

  const [shiftKey, setShiftKey] = useState(false);
  const [lastSelectedIndex, setLastSelectedIndex] = useState<number>(-1);

  useKeyPress(
    '*',
    event => {
      switch (event.key) {
        case 'Shift':
          if (event.type === 'keydown') {
            setShiftKey(true);
          } else if (event.type === 'keyup') {
            setShiftKey(false);
          }
          break;
      }
    },
    { keyup: true, keydown: true },
  );
  // shift+a to select all
  useKeyPress('shift+a', () => {
    if (disableSelection) {
      return;
    }
    dispatch(draft => {
      draft.selectingMediaMode = 'unselect';
      draft.selectedOrUnselectedMedia = [];
    });
  });

  // esc to unselect all
  useKeyPress('esc', () => {
    dispatch(draft => {
      draft.selectingMediaMode = 'select';
      draft.selectedOrUnselectedMedia = [];
    });
  });

  const onMediaSelected = useCallback(
    (mediaId: number, index: number) => (selected: boolean) => {
      if (disableSelection) {
        return;
      }
      if (!selected) {
        dispatch(DraftActionSelectingMedia([mediaId], false));
        return;
      }

      setLastSelectedIndex(index);
      // if with shift key, find all media in between index to be selected
      if (shiftKey && lastSelectedIndex >= 0) {
        dispatch(
          DraftActionSelectingMedia(
            medias
              .slice(Math.min(lastSelectedIndex, index), Math.max(lastSelectedIndex + 1, index + 1))
              .map(_ => _.id),
            true,
          ),
        );
      } else {
        dispatch(DraftActionSelectingMedia([mediaId], true));
      }
    },
    [disableSelection, shiftKey, lastSelectedIndex, dispatch, medias],
  );

  const hasSelectedMedia = useMemo(() => {
    return !!medias?.some(item => isMediaSelected(state, item.id));
  }, [medias, state]);

  const {
    state: { hovering, autoHovering, step },
  } = useWorkflowAssistantState();
  const firstUnlabeledMedia = medias?.find(media => media.mediaStatus === MediaStatusType.Raw);

  return (
    <VirtualGrid
      // Media size is interpreted to number of media per row, the larger the mediaSize, the smaller
      // the number of media we place in a row.
      disabled={disableVirtualGrid}
      itemPerRowOffset={itemPerRowOffset}
      imageRatio={imageRatio ? imageRatio : calcOptimalRatio(medias!)}
      componentList={medias!}
      className={styles.virtualGrid}
      visibleOverflow
      style={{ margin: 0 }}
    >
      {(media, index, itemPerRow) => (
        <MediaContainer
          key={media.id}
          media={media}
          selected={isMediaSelected(state, media.id)}
          onSelected={onMediaSelected(media.id, index)}
          onInfoClick={onInfoClick ? () => onInfoClick(media.id) : undefined}
          labelDisplay={labelDisplay}
          showGroundTruth={showGroundTruthProps ?? showGroundTruth}
          showPredictions={showPredictionsProps ?? showPredictions}
          hasSelectedMedia={hasSelectedMedia}
          thumbnailSize={itemPerRow > 1 ? 'medium' : 'large'}
          showClassChip={showClassChip}
          disableSelection={disableSelection}
          segmentationOpacity={segmentationOpacity}
          enableGrayscaleImage={enableGrayscaleImage}
          highlighted={
            (hovering || autoHovering) &&
            step?.stepName === StepName.Label &&
            firstUnlabeledMedia?.id === media.id
          }
          hinted={step?.stepName === StepName.Label && firstUnlabeledMedia?.id === media.id}
          withDataBrowserFilter={true}
        >
          {renderMediaOverlay?.(media, index)}
        </MediaContainer>
      )}
    </VirtualGrid>
  );
};

export default MediaGrid;
