import React, { useCallback, useEffect, useRef, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { githubGist } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import { Box, Divider, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { Button } from '@clef/client-library';
import { BoxAnnotation, SegmentationAnnotation } from '@clef/client-library';
import {
  AnnotationBase,
  Defect,
  DefectId,
  ImageLevelClassificationRuleCollection,
  InferenceExecutorResult,
  LabelType,
} from '@clef/shared/types';
import { Skeleton } from '@material-ui/lab';
import {
  isAnomalyDetectionPrediction,
  isBoundingBoxPrediction,
  isClassificationPrediction,
  isSegmentationPrediction,
} from '../utils';
import { PureCanvasLabelingAnnotation } from '@/components/Labeling/labelingState';
import ConfidenceThresholdSlider from '../ConfidenceThresholdSlider';
import { LivePrediction } from '../hooks/useLivePredictions';
import { BoundingBoxLabelPreviewList } from '../BoundingBoxLabelPreviewList';
import { ClassificationLabelPreviewList } from '../ClassificationLabelPreviewList';
import { BitMapLabelPreviewList } from '../BitMapLabelPreviewList';
import useStyles from './styles';
import { NoiseFilterSection } from '@/components/Predict/NoiseFilterSection';
import ImageLevelClassificationSettings from '@/pages/DataBrowser/InstantLearningModels/ImageLevelClassificationSettings';
import { AccordionWrapper } from '@/pages/DataBrowser/InstantLearningModels/InstantLearningModelsSidebar';
import { useDefectSelector } from '@/store/defectState/actions';
import { defaultState, useDataBrowserState } from '@/pages/DataBrowser/dataBrowserState';
import { isEqual } from 'lodash';
import { ClientFeatures, useFeatureGateEnabled } from '../../../hooks/useFeatureGate';
import cx from 'classnames';
import { AnomalyDetectionLabelPreviewList } from '../AnomalyDetectionLabelPreviewList';

const a11yProps = (index: any) => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
});

interface SidebarSectionProps {
  labelType: LabelType | null | undefined;
  modelInfo: {
    id: string | undefined;
    threshold: number | undefined;
  };
  updateThreshold?: (newThreshold: number) => void | undefined;
  prediction: LivePrediction;
  defects: Defect[];
  deployComponent?: React.ReactNode;
  annotations: {
    boxAnnotations: BoxAnnotation[] | undefined;
    segmentationAnnotations: (SegmentationAnnotation & PureCanvasLabelingAnnotation)[] | undefined;
    classificationAnnotations: AnnotationBase[] | undefined;
    anomalyDetectionAnnotations: AnnotationBase[] | undefined;
  };
}

const SidebarSection: React.FC<SidebarSectionProps> = ({
  prediction,
  labelType,
  modelInfo,
  updateThreshold,
  defects,
  deployComponent,
  annotations: {
    boxAnnotations,
    classificationAnnotations,
    segmentationAnnotations,
    anomalyDetectionAnnotations,
  },
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const styles = useStyles();
  const allDefects = useDefectSelector();

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };
  const instantLearningPostprocessingEnabled = useFeatureGateEnabled(
    ClientFeatures.InstantLearningPostprocessing,
  );

  const {
    state: {
      TEMP_defectIdToSegmentationAreaThreshold,
      TEMP_imageLevelClassificationRuleCollection,
    },
    dispatch,
  } = useDataBrowserState();

  const previousSegmentationAreaThresholdRef = useRef<Record<DefectId, number>>({
    ...defaultState.TEMP_defectIdToSegmentationAreaThreshold,
  });

  const previousImageLevelClassificationRuleRef = useRef<ImageLevelClassificationRuleCollection>({
    ...defaultState.TEMP_imageLevelClassificationRuleCollection,
  });

  useEffect(() => {
    if (
      isEqual(
        previousSegmentationAreaThresholdRef.current,
        defaultState.TEMP_defectIdToSegmentationAreaThreshold,
      )
    ) {
      previousSegmentationAreaThresholdRef.current = {
        ...TEMP_defectIdToSegmentationAreaThreshold,
      };
    }
  }, [TEMP_defectIdToSegmentationAreaThreshold]);

  useEffect(() => {
    if (
      isEqual(
        previousImageLevelClassificationRuleRef.current,
        defaultState.TEMP_imageLevelClassificationRuleCollection,
      )
    ) {
      previousImageLevelClassificationRuleRef.current = {
        ...TEMP_imageLevelClassificationRuleCollection,
      };
    }
  }, [TEMP_imageLevelClassificationRuleCollection]);

  const resetRawConfig = useCallback(() => {
    dispatch(draft => {
      draft.TEMP_defectIdToSegmentationAreaThreshold = {
        ...previousSegmentationAreaThresholdRef.current,
      };
      draft.TEMP_imageLevelClassificationRuleCollection = {
        ...previousImageLevelClassificationRuleRef.current,
      };
    });
  }, [dispatch, previousSegmentationAreaThresholdRef, previousImageLevelClassificationRuleRef]);

  if (prediction.loading) {
    return (
      <Box display="flex" flexDirection="column" height="100%">
        <Skeleton
          variant="rect"
          height={60}
          width="100%"
          style={{
            marginBottom: 24,
            borderRadius: 10,
            backgroundColor: '#F3F6F9',
          }}
        />
        <Skeleton
          variant="rect"
          height="100%"
          width="100%"
          style={{
            borderRadius: 10,
            backgroundColor: '#F3F6F9',
          }}
        />
      </Box>
    );
  }

  const rawPrediction = (prediction?.result ?? {}) as InferenceExecutorResult;
  const isClassification = labelType === LabelType.Classification;
  const isSegmentation = labelType === LabelType.Segmentation;
  const isBoundingBox = labelType === LabelType.BoundingBox;
  const isAnomalyDetection = labelType === LabelType.AnomalyDetection;
  const isClassificationOrSegmentationOrAnomalyDetection =
    isClassification || isSegmentation || isAnomalyDetection;

  const renderTabs = [
    {
      component: (
        <Box overflow={'hidden auto'}>
          {isBoundingBox &&
            isBoundingBoxPrediction(rawPrediction) &&
            modelInfo.threshold !== undefined && (
              <BoundingBoxLabelPreviewList
                annotations={boxAnnotations}
                defaultExpanded={false}
                classes={{ labelPreviewContainer: styles.labelPreviewContainer }}
                allDefects={defects}
              />
            )}

          {isSegmentation && isSegmentationPrediction(rawPrediction) && (
            <BitMapLabelPreviewList
              annotations={segmentationAnnotations}
              allDefects={defects}
              isLabelMode={false}
              defaultExpanded={false}
            />
          )}
          {isClassification && isClassificationPrediction(rawPrediction) && (
            <ClassificationLabelPreviewList
              annotations={classificationAnnotations}
              allDefects={defects}
              labelType={labelType}
            />
          )}
          {isAnomalyDetection && isAnomalyDetectionPrediction(rawPrediction) && (
            <AnomalyDetectionLabelPreviewList
              annotations={anomalyDetectionAnnotations}
              allDefects={defects}
              labelType={labelType}
              threshold={modelInfo.threshold}
            />
          )}
        </Box>
      ),
    },
    {
      component: !isClassificationOrSegmentationOrAnomalyDetection ? (
        <Box className={styles.jsonTabContent}>
          <SyntaxHighlighter
            language="json"
            style={githubGist}
            wrapLongLines
            customStyle={{
              margin: 0,
              background: 'unset',
              padding: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              position: 'relative',
            }}
            codeTagProps={{
              style: {
                position: 'absolute',
              },
            }}
          >
            {JSON.stringify(rawPrediction, null, 3)}
          </SyntaxHighlighter>
        </Box>
      ) : null,
    },
  ];

  const SidebarSectionContent =
    labelType === LabelType.SegmentationInstantLearning ? (
      instantLearningPostprocessingEnabled ? (
        <>
          <NoiseFilterSection />
          <Divider light className={styles.divider} />
          <AccordionWrapper
            title={t('Image-Level Classification')}
            tooltipTitle={t('Apply rules to assign an image-level output')}
          >
            {allDefects.length ? (
              <ImageLevelClassificationSettings />
            ) : (
              <Typography>{t('You can set rules after creating classes.')}</Typography>
            )}
          </AccordionWrapper>
        </>
      ) : null
    ) : (
      modelInfo.id &&
      updateThreshold &&
      !isClassification && (
        <ConfidenceThresholdSlider
          labelType={labelType}
          registeredModelId={modelInfo.id}
          threshold={modelInfo.threshold || 0}
          updateThreshold={updateThreshold}
        />
      )
    );

  return (
    <Box className={styles.sidebarContainer} data-testid="predict-sidebar">
      <Box
        className={cx(
          labelType === LabelType.SegmentationInstantLearning &&
            styles.instantLearningSidebarSectionContent,
        )}
      >
        {SidebarSectionContent}
      </Box>

      {labelType === LabelType.SegmentationInstantLearning ? (
        <Divider light className={styles.divider} />
      ) : (
        deployComponent && deployComponent
      )}

      <Box className={styles.instantLearningSidebarSection} overflow={'hidden auto'}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          classes={{
            root: styles.tabsRoot,
            flexContainer: styles.tabsFlexContainer,
            indicator: styles.tabsIndicator,
          }}
        >
          {/* Hide prediction panel for now */}
          {labelType !== LabelType.SegmentationInstantLearning && (
            <Tab
              label="Prediction"
              {...a11yProps(0)}
              selected={selectedTab === 0}
              classes={{
                root: styles.tab,
                selected: styles.tabSelected,
              }}
            />
          )}
          {!isClassificationOrSegmentationOrAnomalyDetection && (
            <Tab
              label="JSON output"
              {...a11yProps(1)}
              selected={
                // Hiding the first predicition tab will change the ordering
                selectedTab === (labelType !== LabelType.SegmentationInstantLearning ? 1 : 0)
              }
              classes={{
                root: styles.tab,
                selected: styles.tabSelected,
              }}
            />
          )}
        </Tabs>
        {
          renderTabs[
            // Hiding the first predicition tab will change the ordering
            labelType !== LabelType.SegmentationInstantLearning ? selectedTab : selectedTab + 1
          ].component
        }
      </Box>

      {labelType === LabelType.SegmentationInstantLearning && (
        <Box className={styles.deployComponent}>
          <Divider light className={styles.divider} />
          <Grid container justifyContent="flex-end">
            {instantLearningPostprocessingEnabled && (
              <Grid item>
                <Button
                  id="reset-post-processing-config"
                  variant="text"
                  onClick={resetRawConfig}
                  className={styles.resetButton}
                >
                  {t('Reset')}
                </Button>
              </Grid>
            )}
            <Grid item>{deployComponent && deployComponent}</Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default SidebarSection;
