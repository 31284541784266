import React, { useMemo } from 'react';
import cx from 'classnames';
import { Box, Grid, Tooltip, makeStyles } from '@material-ui/core';
import { LabelType } from '@clef/shared/types';
import { useDefectSelector } from '@/store/defectState/actions';
import { useDataBrowserState } from '@/pages/DataBrowser/dataBrowserState';
import { ClientFeatures, useFeatureGateEnabled } from '@/hooks/useFeatureGate';
import {
  generateImageLevelClassificationText,
  generateNoiseFilterText,
} from '@/pages/DataBrowser/InstantLearningModels/utils';
import InfoIcon from '@material-ui/icons/Info';
import { Typography } from '@clef/client-library';

const useStyles = makeStyles(theme => ({
  selectedModelDetails: {
    width: '400px',
    background: theme.palette.grey[50],
    borderRadius: 10,
    padding: theme.spacing(3, 7),
    gap: theme.spacing(10),
    justifyContent: 'space-between',
  },
  fixedWidth: {
    width: '400px',
  },
  endpointBasicInfoTitle: {
    fontWeight: 700,
    color: theme.palette.grey[500],
  },
  commonFontWeight: {
    fontWeight: 500,
  },
  textWithIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  infoIcon: {
    marginLeft: '6px',
    color: theme.palette.blue[200],
    width: '20px',
    height: '20px',
  },
  halfWidth: {
    flexBasis: '50%',
  },
}));

export const ModelInfoBanner: React.FC<{
  modelName: string;
  threshold?: number;
  labelType?: LabelType | null;
  inLargeModal?: boolean;
}> = ({ modelName, threshold, labelType, inLargeModal }) => {
  const styles = useStyles();
  const instantLearningPostprocessingEnabled = useFeatureGateEnabled(
    ClientFeatures.InstantLearningPostprocessing,
  );
  const allDefects = useDefectSelector();
  const { state } = useDataBrowserState();

  const noiseFilterText = useMemo(
    () => generateNoiseFilterText(allDefects, state.TEMP_defectIdToSegmentationAreaThreshold),
    [allDefects, state.TEMP_defectIdToSegmentationAreaThreshold],
  );

  const imageLevelClassificationText = useMemo(
    () => generateImageLevelClassificationText(state.TEMP_imageLevelClassificationRuleCollection),
    [state.TEMP_imageLevelClassificationRuleCollection],
  );

  return (
    <Grid
      container
      className={cx(styles.selectedModelDetails, {
        [styles.fixedWidth]: !inLargeModal,
      })}
    >
      <Grid item>
        <Box display="flex" flexDirection="column">
          <Typography className={styles.endpointBasicInfoTitle}>{t('Model')}</Typography>
          <Typography className={styles.commonFontWeight} maxWidth={130}>
            {modelName}
          </Typography>
        </Box>
      </Grid>

      {labelType === LabelType.SegmentationInstantLearning ? (
        instantLearningPostprocessingEnabled ? (
          <>
            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <Box>
                  <Typography className={cx(styles.endpointBasicInfoTitle, styles.textWithIcon)}>
                    {t('Noise filtering')}
                    <Tooltip
                      placement="top"
                      title={t('Suppress predicted regions below a certain area')}
                      arrow
                    >
                      <InfoIcon className={styles.infoIcon} />
                    </Tooltip>
                  </Typography>
                </Box>
                <Tooltip placement="top" title={noiseFilterText} arrow>
                  <Typography className={styles.commonFontWeight} maxWidth={130} noWrap>
                    {noiseFilterText}
                  </Typography>
                </Tooltip>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" flexDirection="column">
                <Typography className={cx(styles.endpointBasicInfoTitle, styles.textWithIcon)}>
                  {t('Image-Level classification')}
                  <Tooltip
                    placement="top"
                    title={t('Apply rules to assign an image-level output')}
                    arrow
                  >
                    <InfoIcon className={styles.infoIcon} />
                  </Tooltip>
                </Typography>
                <Tooltip placement="top" title={imageLevelClassificationText} arrow>
                  <Typography className={styles.commonFontWeight} maxWidth={130} noWrap>
                    {imageLevelClassificationText}
                  </Typography>
                </Tooltip>
              </Box>
            </Grid>
          </>
        ) : null
      ) : (
        <>
          {labelType === LabelType.Classification ? null : (
            <Box
              display="flex"
              flexDirection="column"
              className={inLargeModal ? styles.halfWidth : ''}
            >
              <Typography className={cx(styles.endpointBasicInfoTitle, styles.textWithIcon)}>
                {labelType === LabelType.AnomalyDetection
                  ? t('Anomaly Threshold')
                  : t('Confidence Threshold')}
                <Tooltip
                  placement="top"
                  arrow
                  title={
                    labelType === LabelType.AnomalyDetection
                      ? t(
                          `Each pixel in the image has an abnormality score. If any pixel's score exceeds this threshold, the image is classified as Abnormal.`,
                        )
                      : t(
                          'Your model makes several predictions. You can adjust the Confidence Threshold to filter out the less confident predictions so you can focus on the most confident ones.',
                        )
                  }
                >
                  <InfoIcon className={styles.infoIcon} />
                </Tooltip>
              </Typography>
              <Typography className={styles.commonFontWeight} maxWidth={130}>
                {threshold ?? t('Unknown threshold')}
              </Typography>
            </Box>
          )}
        </>
      )}
    </Grid>
  );
};
